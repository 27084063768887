@keyframes animateTab {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.route__dashboard{
    width: 100%;
    animation: animateTab .5s linear;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    background: #F3F3F3;
}

.route__dashboard__header{
    width: 100%;
    padding: 20px;
    height: 50px;
    background: #EF9500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.route__dashboard__header p{
    font-size: 22px;
    color: white;
    text-transform: uppercase;
}

.route__dashboard__money{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.route__dashboard__money__keypi{
    background: #13213C;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.route__dashboard__money__keypi > h1{
    text-align: center;
    font-size: 20px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.route__dashboard__money__keypi > p{
    font-size: 15px;
    width: 100%;
    background: #243352c4;
    border-radius: 5px;
    color: white;
    padding: 3px;
}

.route__dashboard__leads{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.route__dashboard__leads__keypi{
    background: #13213C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.route__dashboard__leads__keypi > h1{
    text-align: center;
    font-size: 20px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.route__dashboard__leads__keypi > p{
    font-size: 15px;
    width: 100%;
    background: #243352c4;
    color: white;
    padding: 3px;
}

.route__dashboard__leads__keypi__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    overflow-y: scroll;
    max-height: 300px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background: #243352c4;
    padding: 5px;
}

.route__dashboard__leads__keypi__content > p{
    font-size: 15px;
    width: 100%;
    color: white;
    padding: 3px;
}

.route__dashboard__h1{
    margin-left: 20px;
    font-size: 22px;
    color: #EF9500;
    border-bottom: 3px solid #EF9500;
    margin-right: 20px;
}

@media screen and (max-width: 938px){
    .route__dashboard__money, .route__dashboard__leads{
        grid-template-columns: 1fr 1fr;
    }
    .route__dashboard__money .last{
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
    }
}

@media screen and (max-width: 652px){
    .route__dashboard__money, .route__dashboard__leads{
        grid-template-columns: 1fr;
    }
    .route__dashboard__money .last{
        grid-column-start: 1;
        grid-column-end: 1;
        width: 100%;
    }
}