.mobile{
    display: none !important;
}

.route__adminPanel{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.route__adminPanel__sidebar{
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-right: 0;
}

.route__adminPanel__sidebar p{
    font-size: 20px;
    cursor: pointer;
    color: #13213C;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    border-radius: 5px 0px 0px 5px;
}

.route__adminPanel__sidebar h1{
    color: #13213C;
}

.route__adminPanel__sidebar strong{
    position: absolute;
    bottom: 20px;
    cursor: pointer;
}

.route__adminPanel__body{
    max-height: 100%;
    min-height: 100vh;
}

.route__adminPanel__sidebar strong{
    color: #13213C;
    font-weight: 400;
}

@media screen and (max-width: 1500px){
    .route__adminPanel{
        grid-template-columns: 200px 1fr;
    }

    .route__adminPanel__sidebar h1{
        font-size: 22px;
    }
}

@media screen and (max-width: 1300px){
    .route__adminPanel{
        grid-template-columns: 1fr;
    }

    .route__adminPanel__sidebar h1{
        font-size: 22px;
    }

    .route__adminPanel__sidebar{
        flex-direction: row;
        max-height: 80px;
        align-items: center;
    }

    .route__adminPanel__sidebar p{
        width: 130px;
        padding-right: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    .route__adminPanel__sidebar strong{
        position: static;
        margin-right: 20px;
        margin-left: auto;
    }
}

@media screen and (max-width: 915px){
    .route__kanban__content{
        grid-template-columns: 1fr 1fr !important;
        row-gap: 100px !important;
    }

    .route__kanban__content__column__list{
        min-height: 1000px;
    }

    .route__adminPanel__sidebar h1{
        font-size: 18px;
    }
    .route__adminPanel__sidebar{
        padding: 10px;
    }

    .route__adminPanel__sidebar strong{
        margin-right: 0px;
    }
}

@media screen and (max-width: 720px){
    .route__adminPanel__sidebar p, .route__adminPanel__sidebar h1, .route__adminPanel__sidebar strong{
        font-size: 14px;
    }

    .route__adminPanel__sidebar p{
        width: 80px;
    }
}

@media screen and (max-width: 554px){
    .route__kanban__content{
        grid-template-columns: 1fr !important;
    }

    .route__adminPanel__sidebar{
        display: none;
    }

    .route__adminPanel__sidebar p{
        width: 100%;
    }
    .route__adminPanel__sidebar strong{
        margin-right: auto;
    }

    .mobile{
        display: flex !important;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: relative;
    }

    .mobile > img{
        width: 28px;
        hyphenate-character: 28px;
        margin-left: auto;
        margin-right: 0;
    }

    .route__adminPanel__sidebar h1{
        font-size: 22px;
    }

    .mob-nav{
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        z-index: 999;
        background: white;
        max-height: 0px;
        overflow: hidden;
        padding: 0;
        transition: all 0.3s ease;
    }

    .mob-nav-active{
        max-height: 1000px;
        padding: 10px;
    }

    .route__kanban__content__column{
        width: 100% !important;
    }
    .route__kanban__header__dropdown__body{
        top: 30px !important;
    }
}