@keyframes animateTab {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.route__kanban{
    width: 100%;
    animation: animateTab .5s linear;
    min-height: 100%;
    background: #F3F3F3;
}

.route__kanban__header{
    width: 100%;
    padding: 20px;
    height: 50px;
    background: #EF9500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.route__kanban__header p{
    font-size: 22px;
    color: white;
    text-transform: uppercase;
}

.route__kanban__content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
    padding: 20px;
    min-height: 93vh;
    overflow-x: scroll;
    max-width: calc(100vw - 300px);
}

.route__kanban__header__dropdown{
    position: relative;
}

.route__kanban__header__dropdown__head{
    width: 180px;
    background: rgb(19, 33, 60);
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.route__kanban__header__dropdown__head > p{
    font-size: 16px;
    color: white;
}

.route__kanban__header__dropdown__head--active{
    border-radius: 5px 5px 0 0;
}

.route__kanban__header__dropdown__body > p{
    cursor: pointer;
    font-size: 14px;
}

.route__kanban__header__dropdown__body{
    max-height: 0px;
    overflow: hidden;
    position: absolute;
    top: 31px;
    right: 0;
    background: rgb(19, 33, 60);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.route__kanban__header__dropdown__body--active{
    max-height: 1000px;
    padding: 20px;
    border-radius: 0 0 5px 5px;
}

.route__kanban__content__column{
    width: 350px;
    max-height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}

.route__kanban__content__column > p {
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: white;
    border-radius: 5px;
    padding: 5px;
}

.route__kanban__content__column__list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    overflow-y: scroll;
    padding: 5px;
}

.route__kanban__content__column__list__item{
    background: white;
    color: rgb(19, 33, 60);
    box-shadow: 0 0 4px gray;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.route__kanban__content__column__list__item__status{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
}

.route__kanban__content__column__list__item__status strong{
    cursor: pointer;
    background: #EF9500;
    width: 100%;
    color: white;
    border-radius: 5px;
    padding: 2px;
    font-weight: 400;
}

.route__kanban__leadInfo{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.397);
    cursor: default;
}

.route__kanban__leadInfo__wrapper{
    background: #F3F3F3;
    border-radius: 5px;
    min-width: 700px;
    max-width: 700px;
    min-height: 700px;
    max-height: 700px;
}

.route__kanban__leadInfo__wrapper__header{
    width: 100%;
    background: #d3d0d0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.route__kanban__leadInfo__wrapper__header > img{
    cursor: pointer;
}

.route__kanban__leadInfo__wrapper__header h1{
    font-size: 20px;
}

.route__kanban__leadInfo__wrapper__body{
    width: 100%;
    padding: 20px;
    overflow-y: scroll;
    max-height: calc(100% - 67px);
}

.route__kanban__leadInfo__wrapper__body__input{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    gap: 20px;
}

.route__kanban__leadInfo__wrapper__body__input textarea{
    outline: none;
    background: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.route__kanban__leadInfo__wrapper__body__quantity{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.route__kanban__leadInfo__wrapper__body__quantity input{
    border: none;
    outline: none;
    height: 35px;
    padding-left: 10px;
    border-radius: 5px;
}

.route__kanban__leadInfo__wrapper__body__quantity button{
    border: none;
    border-radius: 5px;
    background: #EF9500;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 400ms;
    width: 100px;
    height: 35px;
    padding: 5px;
}

.route__kanban__leadInfo__wrapper__body__quantity button:hover{
    background: #da7c03;
}

.route__kanban__leadInfo__wrapper__body__item{
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1500px){
    .route__kanban__content{
        max-width: calc(100vw - 200px);
    }
}

@media screen and (max-width: 1300px){
    .route__kanban__content{
        max-width: 100vw;
    }
}