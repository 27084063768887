@mixin button-hover-animation($size, $position:"relative") {
    position: unquote($position);

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: $size;
        height: $size;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border-radius: 50%;
        transition: background-color 0.3s ease;
    }
    &:hover::after {
        background-color: rgba(128, 128, 128, 0.208);
    }
    &.cloned-element::after {
        display: none !important;
    }
}