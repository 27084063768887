.route__login{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.route__login__wrapper{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: rgb(201, 201, 201);
    padding: 20px;
    border-radius: 5px;
}

.route__login__wrapper input{
    width: 100%;
    height: 50px;
    outline: none;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
}

.route__login__wrapper div{
    background: rgb(177, 175, 175);
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}